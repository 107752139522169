@import '../../../variables.scss';

.Index {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: $appTopMargin;
    justify-content: center;

    .column {
        h1 {
            margin-top: 50px !important;
        }

        max-width: 450px;
        margin: 20px;
        border-radius: 5px;

        h2 {
            text-align: center;
        }

        .text {
            text-align: center;
        }
    }
}