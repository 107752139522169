@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {}

@layer components {
    .paragraph {
        @apply mt-6 text-lg leading-8 text-gray-600;
    }

    .document-image-border {
        @apply border-gray-300 rounded-lg border-solid border-2;
    }
}

@layer utilities {

    a.underline {
        text-decoration: underline;
        color: blue
    }

    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    h1 {
        font-size: 3.5rem;
        font-weight: 400;
        margin: 1rem 0 1rem 0;
    }

    h1 {
        font-size: 3rem;
        font-weight: 400;
        margin: 1rem 0 1rem 0;
    }

    h2 {
        font-size: 2.5rem;
        font-weight: 400;
        margin: 1rem 0 1rem 0;
    }

    h3 {
        font-size: 2rem;
        font-weight: 400;
        margin: 1rem 0 1rem 0;
    }

    h4 {
        font-size: 1.5rem;
        font-weight: 400;
        margin: 1rem 0 1rem 0;
    }

    h5 {
        font-size: 1.2rem;
        font-weight: 400;
        margin: 1rem 0 1rem 0;
    }

    .error {
        color: #c80b0b !important;
        font-size: 1rem;
        margin: 10px 0;
    }

    .success {
        font-size: 1rem;
        color: green !important;
        margin: 10px 0;
    }

    /*#region Global Scrollbar Settings*/

    /* Firefox */
    * {
        /* could be pixels */
        scrollbar-width: none;

        scrollbar-color: var(--secondary) var(--primary);
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 0;
    }

    *::-webkit-scrollbar-track {
        background: var(--primary);
        border-radius: 5px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--secondary);
        border-radius: 14px;
        border: 3px solid var(--primary);
    }

    /* Lists */
    p {
        ul {
            list-style: none;
            padding: 0 0 0 25px;

            li {
                padding-left: 1.3em;
                position: relative;
                margin: 12.5px 0;

                p {
                    margin: 0 0 20px 0 !important;
                }
            }

            li:before {
                width: 8px;
                height: 8px;
                display: inline-block;
                content: "";
                margin-left: -1.3em;
                margin-right: 10px;
                background-color: rgb(79 70 229 / var(--tw-bg-opacity));
            }
        }

        ol {
            padding: 15px 25px;
            list-style: none;

            li {
                padding-left: 1.3em;
                position: relative;
                margin: 12.5px 0;

                p {
                    margin: 0 0 20px 0 !important;
                }

                counter-increment: li;
            }

            li:before {
                content: counter(li);
                font-weight: 700;
                display: inline-block;
                margin-left: -1.3em;
                position: absolute;
                color: rgb(79 70 229 / var(--tw-bg-opacity));

            }
        }
    }
}