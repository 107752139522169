.socials {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    a {
        img {
            height: 25px;
            width: 25px;
            border-radius: 5px;
            margin: 0px 3px !important;
            filter: grayscale(1);

            &:hover {
                filter: grayscale(0);
            }
        }
    }
}